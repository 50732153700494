.AppPartner {
  padding-bottom: 96px;

  @media (max-width: 1680px) {
    padding-bottom: 85px;
  }

  @media (max-width: 1440px) {
    padding-bottom: 80px;
  }

  @media (max-width: 1366px) {
    padding-bottom: 75px;
  }

  @media (max-width: 1280px) {
    padding-bottom: 70px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 65px;
  }

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  &__Item {
    height: 40px;
    width: 120px;
  }

  &__Title {
    margin-bottom: 48px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &__List {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
}
